.table-map1 #p0 {
    left: 311px;
    top: 7px;
    width: 60px;
    height: 59px;
}

.table-map1 #p1 {
    left: 248px;
    top: 7px;
    width: 56px;
    height: 57px;
}

.table-map1 #p2 {
    left: 182px;
    top: 7px;
    width: 56px;
    height: 58px;
}

.table-map1 #p3 {
    left: 116px;
    top: 8px;
    width: 56px;
    height: 57px;
}

.table-map1 #p4 {
    left: 55px;
    top: 6px;
    width: 53px;
    height: 79px;
}

.table-map1 #p5 {
    left: 258px;
    top: 92px;
    width: 70px;
    height: 59px;
}

.table-map1 #p6 {
    left: 179px;
    top: 91px;
    width: 72px;
    height: 61px;
}

.table-map1 #p7 {
    left: 94px;
    top: 91px;
    width: 78px;
    height: 61px;
}

.table-map1 #p8 {
    left: 207px;
    top: 158px;
    width: 121px;
    height: 74px;
}

.table-map1 #p9 {
    left: 144px;
    top: 157px;
    height: 76px;
    width: 57px;
}

.table-map1 #p10 {
    left: 455px;
    top: 126px;
    height: 86px;
    width: 66px;
}

.table-map1 #p11 {
    left: 528px;
    top: 125px;
    height: 88px;
    width: 63px;
}

.table-map1 #p12 {
    left: 455px;
    top: 239px;
    height: 82px;
    width: 67px;
}

.table-map1 #p13 {
    left: 529px;
    top: 241px;
    height: 79px;
    width: 61px;
}

.table-map1 #p14 {
    left: 346px;
    top: 240px;
    width: 70px;
    height: 58px;
}

.table-map1 #p15 {
    left: 270px;
    top: 238px;
    width: 69px;
    height: 60px;
}

.table-map1 #p16 {
    left: 196px;
    top: 237px;
    width: 67px;
    height: 81px;
}

.table-map1 #p17 {
    left: 234px;
    top: 325px;
    width: 92px;
    height: 59px;
}

.table-map1 #p18 {
    left: 332px;
    top: 325px;
    height: 59px;
    width: 84px;
}

.table-map1 #p19 {
    left: 455px;
    top: 327px;
    height: 56px;
    width: 135px;
}

.table-map1 #p20 {
    left: 344px;
    bottom: 95px;
    width: 72px;
    height: 61px;
}

.table-map1 #p21 {
    left: 286px;
    bottom: 75px;
    height: 80px;
    width: 52px;
}

.table-map1 #p22 {
    left: 325px;
    bottom: 9px;
    width: 62px;
    height: 60px;
}

.table-map1 #p23 {
    left: 394px;
    bottom: 9px;
    width: 60px;
    height: 60px;
}

.table-map1 #p24 {
    left: 460px;
    bottom: 10px;
    width: 60px;
    height: 59px;
}

.table-map1 #p25 {
    left: 529px;
    bottom: 10px;
    width: 60px;
    height: 79px;
}

.table-map1 #p26 {
    left: 456px;
    bottom: 97px;
    width: 133px;
    height: 57px;
}