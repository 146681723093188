.table-map6 #p0 {
    left: 250px;
    top: 5px;
    width: 39px;
    height: 41px;
}

.table-map6 #p1 {
    left: 295px;
    top: 5px;
    width: 39px;
    height: 51px;
}

.table-map6 #p2 {
    left: 338px;
    top: 5px;
    width: 39px;
    height: 51px;
}

.table-map6 #p3 {
    left: 382px;
    top: 5px;
    width: 37px;
    height: 51px;
}

.table-map6 #p4 {
    left: 423px;
    top: 5px;
    width: 37px;
    height: 51px;
}

.table-map6 #p5 {
    left: 465px;
    top: 5px;
    width: 37px;
    height: 51px;
}

.table-map6 #p6 {
    left: 507px;
    top: 5px;
    width: 45px;
    height: 51px;
}

.table-map6 #p7 {
    left: 556px;
    top: 5px;
    width: 45px;
    height: 51px;
}

.table-map6 #p8 {
    left: 606px;
    top: 5px;
    width: 78px;
    height: 42px;
}

.table-map6 #p9 {
    left: 630px;
    top: 52px;
    width: 53px;
    height: 36px;
}

.table-map6 #p10 {
    left: 630px;
    top: 95px;
    width: 53px;
    height: 36px;
}

.table-map6 #p11 {
    left: 588px;
    top: 110px;
    width: 37px;
    height: 38px;
}

.table-map6 #p12 {
    left: 547px;
    top: 78px;
    width: 37px;
    height: 53px;
}

.table-map6 #p13 {
    left: 507px;
    top: 78px;
    width: 35px;
    height: 53px;
}

.table-map6 #p14 {
    left: 465px;
    top: 78px;
    width: 37px;
    height: 53px;
}

.table-map6 #p15 {
    left: 424px;
    top: 78px;
    width: 37px;
    height: 53px;
}

.table-map6 #p16 {
    left: 382px;
    top: 78px;
    width: 37px;
    height: 53px;
}

.table-map6 #p17 {
    left: 340px;
    top: 78px;
    width: 37px;
    height: 53px;
}

.table-map6 #p18 {
    left: 300px;
    top: 78px;
    width: 37px;
    height: 53px;
}

.table-map6 #p19 {
    left: 300px;
    top: 135px;
    width: 36px;
    height: 49px;
}

.table-map6 #p20 {
    left: 340px;
    top: 135px;
    width: 36px;
    height: 49px;
}

.table-map6 #p21 {
    left: 383px;
    top: 135px;
    width: 36px;
    height: 49px;
}

.table-map6 #p22 {
    left: 425px;
    top: 135px;
    width: 36px;
    height: 49px;
}

.table-map6 #p23 {
    left: 465px;
    top: 135px;
    width: 36px;
    height: 49px;
}

.table-map6 #p24 {
    left: 507px;
    top: 135px;
    width: 35px;
    height: 49px;
}

.table-map6 #p25 {
    left: 548px;
    top: 135px;
    width: 34px;
    height: 49px;
}

.table-map6 #p26 {
    left: 588px;
    top: 153px;
    width: 37px;
    height: 47px;
}

.table-map6 #p27 {
    left: 588px;
    top: 206px;
    width: 37px;
    height: 59px;
}

.table-map6 #p28 {
    left: 548px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p29 {
    left: 508px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p30 {
    left: 466px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p31 {
    left: 425px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p32 {
    left: 383px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p33 {
    left: 341px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p34 {
    left: 301px;
    top: 206px;
    width: 34px;
    height: 50px;
}

.table-map6 #p35 {
    left: 301px;
    top: 261px;
    width: 34px;
    height: 46px;
}

.table-map6 #p36 {
    left: 341px;
    top: 261px;
    width: 34px;
    height: 46px;
}

.table-map6 #p37 {
    left: 383px;
    top: 261px;
    width: 34px;
    height: 46px;
}

.table-map6 #p38 {
    left: 425px;
    top: 262px;
    width: 34px;
    height: 45px;
}

.table-map6 #p39 {
    left: 466px;
    top: 261px;
    width: 34px;
    height: 45px;
}

.table-map6 #p40 {
    left: 508px;
    top: 261px;
    width: 34px;
    height: 47px;
}

.table-map6 #p41 {
    left: 548px;
    top: 261px;
    width: 34px;
    height: 45px;
}

.table-map6 #p42 {
    left: 609px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p43 {
    left: 562px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p44 {
    left: 518px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p45 {
    left: 475px;
    top: 329px;
    width: 36px;
    height: 38px;
}

.table-map6 #p46 {
    left: 430px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p47 {
    left: 387px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p48 {
    left: 343px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p49 {
    left: 300px;
    top: 329px;
    width: 39px;
    height: 38px;
}

.table-map6 #p50 {
    left: 300px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p51 {
    left: 343px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p52 {
    /* left: 387px;
    top: 375px;
    width: 39px;
    height: 38px; */

    left: 185px;
    bottom: 172px;
    width: 42px;
    height: 32px;
}

.table-map6 #p53 {
    left: 387px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p54 {
    left: 430px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p55 {
    left: 475px;
    top: 375px;
    width: 36px;
    height: 38px;
}

.table-map6 #p56 {
    left: 518px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p57 {
    left: 562px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p58 {
    left: 609px;
    top: 375px;
    width: 39px;
    height: 38px;
}

.table-map6 #p59 {
    left: 609px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p60 {
    left: 562px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p61 {
    left: 518px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p62 {
    left: 475px;
    top: 438px;
    width: 36px;
    height: 38px;
}

.table-map6 #p63 {
    left: 430px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p64 {
    left: 387px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p65 {
    left: 343px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p66 {
    left: 300px;
    top: 438px;
    width: 39px;
    height: 38px;
}

.table-map6 #p67 {
    left: 300px;
    top: 484px;
    width: 39px;
    height: 38px;
}

.table-map6 #p68 {
    left: 343px;
    top: 484px;
    width: 39px;
    height: 38px;
}

.table-map6 #p69 {
    left: 387px;
    top: 484px;
    width: 39px;
    height: 38px;
}

.table-map6 #p70 {
    left: 430px;
    top: 484px;
    width: 39px;
    height: 38px;
}

.table-map6 #p71 {
    left: 475px;
    top: 484px;
    width: 36px;
    height: 38px;
}

.table-map6 #p72 {
    left: 518px;
    top: 484px;
    width: 39px;
    height: 38px;
}

.table-map6 #p73 {
    left: 562px;
    top: 484px;
    width: 39px;
    height: 38px;
}

.table-map6 #p74 {
    left: 609px;
    top: 484px;
    width: 39px;
    height: 38px;
}
.table-map6 #p75 {
    left: 609px;
    top: 545px;
    width: 39px;
    height: 38px;
}

.table-map6 #p76 {
    left: 562px;
    top: 545px;
    width: 39px;
    height: 38px;
}

.table-map6 #p77 {
    left: 518px;
    top: 545px;
    width: 39px;
    height: 38px;
}

.table-map6 #p78 {
    left: 475px;
    top: 545px;
    width: 36px;
    height: 38px;
}

.table-map6 #p79 {
    left: 430px;
    top: 545px;
    width: 39px;
    height: 38px;
}

.table-map6 #p80 {
    left: 387px;
    top: 545px;
    width: 39px;
    height: 38px;
}

.table-map6 #p81 {
    left: 343px;
    top: 545px;
    width: 39px;
    height: 38px;
}

.table-map6 #p82 {
    left: 300px;
    top: 545px;
    width: 39px;
    height: 38px;
}
.table-map6 #p83 {
    left: 300px;
    top: 588px;
    width: 39px;
    height: 38px;
}

.table-map6 #p84 {
    left: 343px;
    top: 588px;
    width: 39px;
    height: 38px;
}

.table-map6 #p85 {
    left: 387px;
    top: 588px;
    width: 39px;
    height: 38px;
}

.table-map6 #p86 {
    left: 430px;
    top: 588px;
    width: 39px;
    height: 38px;
}

.table-map6 #p87 {
    left: 475px;
    top: 588px;
    width: 36px;
    height: 38px;
}

.table-map6 #p88 {
    left: 518px;
    top: 588px;
    width: 39px;
    height: 38px;
}

.table-map6 #p89 {
    left: 562px;
    top: 588px;
    width: 86px;
    height: 38px;
}

.table-map6 #p90 {
    left: 609px;
    top: 633px;
    width: 39px;
    height: 38px;
}
.table-map6 #p91 {
    left: 515px;
    bottom: 28px;
    width: 64px;
    height: 27px;
}
.table-map6 #p92 {
    left: 441px;
    bottom: 28px;
    width: 49px;
    height: 27px;
}
.table-map6 #p93 {
    left: 388px;
    bottom: 28px;
    width: 49px;
    height: 27px;
}
.table-map6 #p94 {
    left: 336px;
    bottom: 28px;
    width: 47px;
    height: 27px;
}
.table-map6 #p95 {
    left: 281px;
    bottom: 28px;
    width: 47px;
    height: 27px;
}
.table-map6 #p96 {
    left: 239px;
    bottom: 28px;
    width: 36px;
    height: 48px;
}
.table-map6 #p97 {
    left: 202px;
    bottom: 28px;
    width: 33px;
    height: 48px;
}
.table-map6 #p98 {
    left: 165px;
    bottom: 28px;
    width: 33px;
    height: 48px;
}
.table-map6 #p99 {
    left: 185px;
    bottom: 98px;
    width: 42px;
    height: 32px;
}
.table-map6 #p100 {
    left: 185px;
    bottom: 135px;
    width: 42px;
    height: 32px;
}
/* .table-map6 #p101 {
    left: 185px;
    bottom: 172px;
    width: 42px;
    height: 32px;
} */