.table-map5 #p0 {
    left: 2px;
    bottom: 117px;
    width: 71px;
    height: 59px;
}

.table-map5 #p1 {
    left: 2px;
    bottom: 181px;
    width: 71px;
    height: 58px;
}

.table-map5 #p2 {
    left: 2px;
    bottom: 244px;
    width: 71px;
    height: 56px;
}

.table-map5 #p3 {
    left: 2px;
    bottom: 306px;
    width: 71px;
    height: 57px;
}

.table-map5 #p4 {
    left: 2px;
    bottom: 368px;
    width: 71px;
    height: 57px;
}

.table-map5 #p5 {
    left: 2px;
    bottom: 429px;
    width: 71px;
    height: 59px;
}

.table-map5 #p6 {
    left: 2px;
    bottom: 493px;
    width: 71px;
    height: 57px;
}

.table-map5 #p7 {
    left: 2px;
    bottom: 554px;
    width: 71px;
    height: 57px;
}

.table-map5 #p8 {
    left: 2px;
    bottom: 617px;
    width: 71px;
    height: 57px;
}

.table-map5 #p9 {
    left: 2px;
    top: 3px;
    width: 112px;
    height: 57px;
}

.table-map5 #p10 {
    left: 119px;
    top: 3px;
    width: 112px;
    height: 57px;
}

.table-map5 #p11 {
    left: 235px;
    top: 3px;
    width: 112px;
    height: 57px;
}

.table-map5 #p12 {
    left: 261px;
    top: 65px;
    height: 53px;
    width: 86px;
}

.table-map5 #p13 {
    left: 261px;
    top: 122px;
    height: 53px;
    width: 86px;
}

.table-map5 #p14 {
    left: 261px;
    top: 181px;
    height: 53px;
    width: 86px;
}

.table-map5 #p15 {
    left: 261px;
    top: 239px;
    height: 53px;
    width: 86px;
}

.table-map5 #p16 {
    left: 261px;
    top: 296px;
    height: 53px;
    width: 86px;
}

.table-map5 #p17 {
    left: 261px;
    top: 355px;
    height: 53px;
    width: 86px;
}
.table-map5 #p18 {
    left: 261px;
    top: 414px;
    height: 53px;
    width: 86px;
}
.table-map5 #p19 {
    left: 261px;
    top: 472px;
    height: 53px;
    width: 86px;
}

.table-map5 #p20 {
    left: 107px;
    top: 93px;
    height: 80px;
    width: 56px;
}
.table-map5 #p21 {
    left: 107px;
    top: 178px;
    height: 80px;
    width: 56px;
}
.table-map5 #p22 {
    left: 107px;
    top: 263px;
    height: 80px;
    width: 56px;
}
.table-map5 #p23 {
    left: 107px;
    top: 348px;
    height: 80px;
    width: 56px;
}
.table-map5 #p24 {
    left: 107px;
    top: 433px;
    height: 80px;
    width: 56px;
}
.table-map5 #p25 {
    left: 107px;
    top: 518px;
    height: 80px;
    width: 56px;
}
.table-map5 #p26 {
    left: 168px;
    top: 93px;
    height: 80px;
    width: 56px;
}
.table-map5 #p27 {
    left: 168px;
    top: 178px;
    height: 80px;
    width: 56px;
}
.table-map5 #p28 {
    left: 168px;
    top: 263px;
    height: 80px;
    width: 56px;
}
.table-map5 #p29 {
    left: 168px;
    top: 348px;
    height: 80px;
    width: 56px;
}
.table-map5 #p30 {
    left: 168px;
    top: 433px;
    height: 80px;
    width: 56px;
}
.table-map5 #p31 {
    left: 168px;
    top: 518px;
    height: 80px;
    width: 56px;
}
.table-map5 #p32 {
    left: 261px;
    top: 531px;
    height: 53px;
    width: 86px;
}
