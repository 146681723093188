.profile-dropdown button {
    background-color: transparent !important;
    color: #000 !important;
    border: none !important;
}

.profile-dropdown .dropdown-menu>a {
    justify-content: start;
}
body [dir="ltr"] .profile-dropdown .dropdown-menu>a {
    justify-content: end;
    flex-direction: row-reverse;
}