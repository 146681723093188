.table-map4 #p0 {
    left: 3px;
    bottom: 98px;
    width: 76px;
    height: 61px;
}

.table-map4 #p1 {
    left: 3px;
    bottom: 163px;
    width: 76px;
    height: 56px;
}

.table-map4 #p2 {
    left: 3px;
    bottom: 224px;
    width: 76px;
    height: 56px;
}

.table-map4 #p3 {
    left: 3px;
    bottom: 287px;
    width: 76px;
    height: 56px;
}

.table-map4 #p4 {
    left: 3px;
    bottom: 351px;
    width: 76px;
    height: 56px;
}

.table-map4 #p5 {
    left: 3px;
    bottom: 412px;
    width: 68px;
    height: 49px;
}

.table-map4 #p6 {
    left: 3px;
    top: 3px;
    width: 67px;
    height: 44px;
}

.table-map4 #p7 {
    left: 76px;
    top: 3px;
    width: 71px;
    height: 74px;
}

.table-map4 #p8 {
    left: 152px;
    top: 3px;
    width: 62px;
    height: 74px;
}

.table-map4 #p9 {
    left: 220px;
    top: 3px;
    height: 74px;
    width: 57px;
}

.table-map4 #p10 {
    left: 270px;
    top: 105px;
    height: 58px;
    width: 76px;
}

.table-map4 #p11 {
    left: 270px;
    top: 167px;
    height: 60px;
    width: 77px;
}

.table-map4 #p12 {
    left: 270px;
    top: 232px;
    height: 56px;
    width: 77px;
}

.table-map4 #p13 {
    left: 270px;
    top: 293px;
    height: 55px;
    width: 77px;
}

.table-map4 #p14 {
    left: 270px;
    top: 355px;
    height: 58px;
    width: 77px;
}

.table-map4 #p15 {
    left: 283px;
    top: 417px;
    height: 40px;
    width: 63px;
}

.table-map4 #p16 {
    left: 283px;
    top: 464px;
    height: 46px;
    width: 64px;
}
