.floor-col {
    padding-left: 15px;
}

.floor-col .image,
.floor-col .image img {
    width: 350px;
    height: 200px;
}

.floor-col .image img {
    object-fit: cover;
}

.floor-col .building_name {
    color: #000;
    font-weight: 600;
    font-size: 1.05rem;
}

.floor-col .floor_name {
    color: #A580CA;
    font-size: 1.03rem;
}

.floor-col .floor_code_title,
.floor-col .floor_status_title {
    color: #707070;
    font-size: 1.03rem;
}

.floor-col .floor_code {
    color: #A580CA;
    font-size: 1.03rem;
}

body[dir="ltr"] .floor-col {
    padding-left: 0;
    padding-right: 15px;
}