.invoice_wrapper {
    border: 1px dashed #ddd;
    padding: 29px 27px !important;
    border-radius: 8px;
}

.invoice_wrapper .header {
    border-bottom: 1px solid #ddd
}

.invoice_wrapper .header .contact_info {
    border-right: 5px solid #0b5ed7;
    padding-right: 15px;
}

.invoice_wrapper .header .logo img {
    width: 100px;
    height: 100px;
}

.invoice_wrapper .header .contact_info h1,
.invoice_wrapper .header .contact_info h2,
.invoice_wrapper .header .contact_info h3 {
    font-size: 16px;
}

.invoice_wrapper .body {
    padding-top: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.invoice_wrapper .body .item:nth-child(odd) {
    background-color: rgb(221 221 221 / 26%) !important;
    padding: 10px;
    border-radius: 4px;
}

.invoice_wrapper .body .item b,
.invoice_wrapper .footer .item b {
    width: 130px;
}

.invoice_wrapper .footer {
    padding-top: 37px;
}

.invoice_wrapper .footer .item img {
    left: -28px;
    background-color: #fff;
    bottom: -43px;
    width: 185px;
    height: 81px;
}

@media print {

    nav.sidebar-menu,
    #content>.bg-white,
    #breadcrumb_area,
    .buttons-warpper,
    .hide-in-print{
        display: none;
    }

    #main-container {
        padding: 0 !important;
    }

    #content {
        background-color: #fff !important;
    }
}