.login .container {
    width: 100%;
    max-width: 100%;
}

.login .form-col,
.login .image-col {
    height: 100vh;
}

.login .image-col {
    padding: 0 100px;
}

.login .form-col p {
    color: #707070;
    width: 90%;
    line-height: 1.5rem;
    font-size: 1.04rem;
}

.login .form-col h1 {
    color: #A580CA;
    margin-bottom: 18px;
}

.login .form-col form {
    padding: 40px 30px;
    width: 80%;
}

.login .form-col label {
    font-size: 1.2rem;
}

.login .form-col .input-icon {
    color: #707070;
    bottom: 24px;
    right: 15px;
}

.login .form-col input {
    border: 1px solid #A580CA;
    border-radius: 10px;
    color: #707070;
    height: 60px;
    padding-right: 41px
}

.login .form-col button {
    height: 54px;
    font-size: 1.2rem;
}

.login .form-col button:hover {
    background-color: #07BFBF;
}

@media (max-width: 991px) {
    .login .image-col {
        padding: 0 20px;
    }

    .login .form-col form {
        width: 90%;
    }
}

@media (max-width: 767px) {
    .login .image-col {
        display: none !important;
    }
}