.table-map2 #p0 {
    left: 319px;
    top: 7px;
    width: 54px;
    height: 56px;
}

.table-map2 #p1 {
    left: 254px;
    top: 7px;
    width: 56px;
    height: 57px;
}

.table-map2 #p2 {
    left: 189px;
    top: 6px;
    width: 56px;
    height: 58px;
}

.table-map2 #p3 {
    left: 124px;
    top: 7px;
    width: 56px;
    height: 57px;
}

.table-map2 #p4 {
    left: 62px;
    top: 7px;
    width: 56px;
    height: 78px;
}

.table-map2 #p5 {
    left: 263px;
    top: 92px;
    width: 68px;
    height: 59px;
}

.table-map2 #p6 {
    left: 187px;
    top: 92px;
    width: 70px;
    height: 59px
}

.table-map2 #p7 {
    left: 104px;
    top: 92px;
    width: 76px;
    height: 59px;
}

.table-map2 #p8 {
    left: 213px;
    top: 158px;
    width: 119px;
    height: 56px;
}

.table-map2 #p9 {
    left: 151px;
    top: 158px;
    height: 75px;
    width: 56px;
}

.table-map2 #p10 {
    left: 457px;
    top: 125px;
    height: 87px;
    width: 66px;
}

.table-map2 #p11 {
    left: 530px;
    top: 125px;
    height: 71px;
    width: 59px;
}

.table-map2 #p12 {
    left: 458px;
    top: 239px;
    height: 81px;
    width: 65px;
}

.table-map2 #p13 {
    left: 529px;
    top: 251px;
    height: 71px;
    width: 61px;
}

.table-map2 #p14 {
    left: 350px;
    top: 240px;
    width: 68px;
    height: 57px;
}

.table-map2 #p15 {
    left: 275px;
    top: 240px;
    width: 69px;
    height: 58px;
}

.table-map2 #p16 {
    left: 200px;
    top: 240px;
    width: 69px;
    height: 77px;
}

.table-map2 #p17 {
    left: 240px;
    top: 325px;
    width: 89px;
    height: 59px;
}

.table-map2 #p18 {
    left: 338px;
    top: 325px;
    height: 57px;
    width: 79px;
}

.table-map2 #p19 {
    left: 457px;
    top: 329px;
    height: 118px;
    width: 132px;
}

.table-map2 #p20 {
    left: 349px;
    bottom: 96px;
    width: 70px;
    height: 58px;
}

.table-map2 #p21 {
    left: 288px;
    bottom: 78px;
    height: 74px;
    width: 54px;
}

.table-map2 #p22 {
    left: 328px;
    bottom: 10px;
    width: 62px;
    height: 58px;
}

.table-map2 #p23 {
    left: 398px;
    bottom: 10px;
    width: 60px;
    height: 58px;
}

.table-map2 #p24 {
    left: 463px;
    bottom: 10px;
    width: 60px;
    height: 59px;
}

.table-map2 #p25 {
    left: 530px;
    bottom: 9px;
    width: 60px;
    height: 79px;
}
