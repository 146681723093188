.wrapper-invoice {
    display: flex;
    justify-content: center;
}

.wrapper-invoice .invoice {
    height: auto;
    background: #fff;
    padding: 5vh;
    margin-top: 5vh;
    width: 100%;
    box-sizing: border-box;
    border: 1px dashed #dcdcdc;
    border-radius: 8px;
}

.wrapper-invoice .invoice .invoice-information {
    text-align: right;
}

.wrapper-invoice .invoice .invoice-information b {
    color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-information p {
    font-size: 16px;
    color: gray;
}

.wrapper-invoice .invoice .invoice-logo-brand h2 {
    text-transform: uppercase;
    font-size: 2.9vh;
    color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-logo-brand img {
    max-width: 180px;
    width: 100%;
    object-fit: fill;
}

.wrapper-invoice .invoice .invoice-head {
    display: flex;
    margin-top: 0;
    border-top: 1px solid #ddd;
    padding-top: 15px;
}

.wrapper-invoice .invoice .invoice-head .head {
    width: 100%;
    box-sizing: border-box;
}

.wrapper-invoice .invoice .invoice-head .client-info {
    text-align: left;
}

.wrapper-invoice .invoice .invoice-head .client-info h2 {
    font-weight: 500;
    letter-spacing: 0.3px;
    color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-head .client-info p {
    font-size: 17px;
    color: gray;
    margin-bottom: 0.5rem;
}

.wrapper-invoice .invoice .invoice-head .client-data {
    text-align: right;
}

.wrapper-invoice .invoice .invoice-head .client-data h2 {
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 2vh;
    color: "#0F172A";
}

.wrapper-invoice .invoice .invoice-head .client-data p {
    font-size: 17px;
    color: gray;
}

.wrapper-invoice .invoice .invoice-body {
    border-top: 1px solid #ddd !important;
    padding-top: 0;
    margin-top: 6px;
}

.wrapper-invoice .invoice .invoice-body .flex-table {
    display: flex;
}

.wrapper-invoice .invoice .invoice-body .flex-table .flex-column {
    width: 100%;
}

.wrapper-invoice .amounts {
    text-align: left;
}

.rtl {
    direction: rtl;
}

.rtl .invoice-information {
    float: left !important;
    text-align: left !important;
}

.rtl .invoice-head .client-info {
    text-align: right !important;
}

.rtl .invoice-head .client-data {
    text-align: left !important;
}


.print-show{
    display: none;
}


@media print {

    .inner-box,
    .main-container {
        width: 100%;
        padding: 0 !important;
    }

    .wrapper-invoice .invoice {
        margin: 0 !important;
        padding: 15px 15px !important
    }

    @page {
        size: A4;
        margin: 5mm 1mm;
    }

    .invoice-form,
    .modal-header,
    .modal-footer,
    .alert-info,
    .booking-office-content {
        display: none;
    }

    .modal-content {
        border: none !important;
        background-color: transparent !important;
    }

    .modal.show .modal-dialog {
        width: 100% !important;
        max-width: 100%;
    }

    .print-hide{
        display: none;
    }

    .print-show{
        display: inline-block;
    }

    .additional-invoice-info{
        flex-direction: column;
    }
    .additional-invoice-info>div{
        width: 100%;
        flex: auto;
    }


}