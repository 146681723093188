.breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
    padding-right: 0;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: right;
    padding-left: var(--bs-breadcrumb-item-padding-x);
}

#breadcrumb_area nav ol {
    margin-bottom: 0;
}

#breadcrumb_area nav ol .default {
    color: #000000;
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 1.5rem;
}

body[dir="ltr"] .breadcrumb-item+.breadcrumb-item::before{
    float: left;
}