.building-col .image,
.building-col .image img{
    height: 196px;
    width: 100%;
}
.building-col .image img{
    object-fit: cover;
}
.building-col h1 {
    color: #000;
    font-size: 1.5rem;
    line-height: 2rem;
}

.building-col span {
    font-size: 0.7rem;
}

.building-col span.bg-danger{
    background-color: #A580CA !important;
    color: #fff !important;
}
.building-col span.bg-success{
    background-color: #70707029  !important;
    color: #000 !important;
}

.building-col h2 {
    color: #707070;
    font-size: 1rem;
}

.building-col h3 {
    color: #A580CA;
    font-size: 1rem;
}

.building-col p {
    color: #707070;
    font-size: 1rem;
}