.profile-pic,
.profile-pic img {
    width: 126px;
    height: 126px;
}

.profile-pic img {
    object-fit: cover;
    border: 3px solid #08D1D1;
}

.profile-name {
    color: #000000;
    font-size: 1.3rem;
}

ul.profile-info>li {
    color: #000000;
    font-size: 0.9rem;
    font-weight: 500;
}

.user-profile-reports .green-box {
    background: #07BFBF 0% 0% no-repeat padding-box;
    color: #fff;
}

.user-profile-reports .red-box {
    background: #F64E2D 0% 0% no-repeat padding-box;
    color: #fff;
}

.filtration-tab a {
    color: #707070;
    border-radius: 5px;
    background-color: #fff;
    border: none;
}

.filtration-tab a:hover,
.filtration-tab a.active {
    background-color: #2185E9;
}