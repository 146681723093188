.nav.sidebar-menubar-brand {
    color: #A580CA;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-bottom: 20px;
    padding-top: 20px;
}

.sidebar-menu {
    padding-right: 0;
}

nav.sidebar-menu {
    width: 215px;
}


nav.sidebar-menu nav>a.active {
    background-color: whitesmoke;
    color: #A580CA;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px
}

body[dir="ltr"] nav.sidebar-menu nav>a.active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px
}

nav.sidebar-menu nav>a {
    color: #000000;
    position: relative;
}

nav.sidebar-menu nav>a.active::after,
nav.sidebar-menu nav>a.active::before {
    position: absolute;
    left: 0;
    background-color: transparent;
    font-size: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid transparent;
    border-left: 15px solid whitesmoke;
    width: 40px;
    height: 40px;
    content: "line";
}

nav.sidebar-menu nav>a.active::after {
    top: -15px;
}

nav.sidebar-menu nav>a.active::before {
    bottom: -15px;
}

nav.sidebar-menu nav>a>svg {
    padding-left: 10px;
}

body[dir="ltr"] nav.sidebar-menu nav>a.active::after,
body[dir="ltr"] nav.sidebar-menu nav>a.active::before {
    left: auto;
    right: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid whitesmoke;
    border-left: 15px solid transparent;
}

body[dir="ltr"] nav.sidebar-menu nav>a>svg {
    padding-left: 0;
    padding-right: 10px;
    font-size: 12px;
}

body[dir="ltr"] .sidebar-menu {
    padding-right: 0;
    padding-left: 15px;
}

.nav-item-with-submenu .menu-item-title {
    cursor: pointer;
}

.nav-item-with-submenu .menu-item-title svg.main-icon {
    padding-left: 10px;
}

nav.sidebar-menu svg.main-icon {
    font-size: 18px !important;
}

.nav-item-with-submenu .sub-menu {
    background-color: #f2f2f2;
}

.nav-item-with-submenu .sub-menu a {
    border-bottom: 1px solid #ece4e4;
    color: #000;
}

.nav-item-with-submenu .sub-menu a:hover,
.nav-item-with-submenu .sub-menu a.active {
    background-color: #ddd;
}