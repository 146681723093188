@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600&display=swap');

body {
  font-family: 'Baloo Bhaijaan 2', cursive;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.cursor-pointer {
  cursor: pointer;
}

/* padding */
.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px
}

.pr-15 {
  padding-right: 15px;
}

.pl-15 {
  padding-left: 15px
}

.pt-20 {
  padding-top: 20px
}

.pb-20 {
  padding-bottom: 20px
}

.pt-15 {
  padding-top: 15px
}

.pb-15 {
  padding-bottom: 15px
}


.mh-500 {
  min-height: 500px;
}

.box-shadow1 {
  box-shadow: 0px -2px 8px rgb(0 0 0 / 16%);
}

.box-shadow2 {
  box-shadow: 0px 3px 6px #CCCCCCB3;
}

.box-shadow3 {
  box-shadow: 0 0 6px #CCCCCCB3;
}

.badge_as_dots {
  padding: 0 !important;
  height: 10px;
  width: 10px;
  display: inline-block;
}

/* border radius */
.rouned-10 {
  border-radius: 10px;
}

.rouned-15 {
  border-radius: 10px;
}

.rouned-30 {
  border-radius: 30px;
}


/* bg color */
.bg-purple {
  background-color: #A580CA;
}


.bg-default {
  background-color: #626272;
}


.bg-light-gray {
  background-color: #F5F5F5;
}

.bg-dark-gray {
  background-color: rgb(242 242 242);
}

/* txt color */
.txt-color-white {
  color: #fff;
}

.txt-color-blue {
  color: #0000ff;
}

.txt-color-orange {
  color: #ffa500;
}

.txt-color-red {
  color: #ff0000;
}

/* width */
.w-90 {
  width: 90%;
}

.w-60 {
  width: 60%;
}

/* buttons */
.btn-white {
  background-color: #FFFFFF;
  border: 1px solid #A580CA;
  color: #A580CA;
}

.btn-white:hover {
  background-color: #A580CA;
  color: #fff;
  border: 1px solid #A580CA;
}

.btn-black {
  background-color: #626272;
  border: 1px solid #626272;
  color: #fff;
}

.btn-black:hover {
  background-color: #198754;
  color: #fff;
  border: 1px solid #198754;
}

.btn-purple {
  background-color: #A580CA;
  color: #fff;
  border: 1px solid #A580CA;
}

.btn-purple:hover {
  background-color: #07BFBF;
  border: 1px solid #07BFBF;
  color: #fff;
}

.btn-green {
  background-color: #15B83B;
  color: #fff;
  border: 1px solid #15B83B;
}

.btn-light-green {
  background-color: #07BFBF;
  color: #fff;
  border: 1px solid #07BFBF;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }
}

/* circle-loader-border */
.circle-loader-border,
.dropdown-loader {
  border-color: #0d6efd;
  border-right-color: transparent;
}

/* Dropdown Loader */
.dropdown-loader {
  top: 37px;
  left: 10px;
  width: 1.6rem;
  height: 1.6rem;
  border-width: 2px;
}

#content {
  min-height: 800px;
}

/* input */
.form-control[readonly] {
  background-color: #f5f5f5;
}

/* Check Box */
.custom-checkbox .form-check {
  position: relative;
}

.custom-checkbox .form-check>input {
  position: absolute;
}

.custom-checkbox label {
  padding: 0 38px;
}

/* DatetimePicker */
.react-datetime-picker__wrapper {
  border: none !important
}

/* Table */
table td,
table th {
  font-size: 13px;
  padding: 0.7rem 0.3rem !important;
  vertical-align: middle;
}

table thead th {
  background: #F5F5F5 !important;
}

table td .dropdown>button {
  padding: 0.31rem 0.5rem;
}

.table-map span {
  position: absolute;
  display: block;
  background-color: #fff;
  width: 49px;
  height: 31px;
  color: #000;
  cursor: pointer;
  font-size: 14px;
}

.table-map span>p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

/* Modal  */
.modal-content .modal-header>.h4 {
  font-size: 1.2rem;
}

.modal-header .btn-close {
  padding: 0 !important;
  margin: 0 !important;
}

/* pagination */
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-link {
  box-shadow: none !important;
}

.pagination-dropdown>select {
  width: 80px;
}


.dark-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 90000;
}

.float-modal {
  right: 0;
  left: 0;
  z-index: 90000;
}

.float-modal .btn-primary,
.float-modal .btn-danger {
  margin-top: 32px;
}

/* Responsive  */
@media (max-width: 991px) {
  .main-wrapper {
    flex-direction: column;
  }

  .sidebar-menu {
    position: absolute;
    width: 100%;
    z-index: 900;
  }
}