.table-map3 #p0 {
    left: 30px;
    top: 206px;
    width: 45px;
    height: 28px;
}

.table-map3 #p1 {
    left: 30px;
    top: 175px;
    width: 45px;
    height: 28px;
}

.table-map3 #p2 {
    left: 30px;
    top: 144px;
    width: 45px;
    height: 28px;
}

.table-map3 #p3 {
    left: 30px;
    top: 113px;
    width: 45px;
    height: 28px;
}

.table-map3 #p4 {
    left: 30px;
    top: 81px;
    width: 45px;
    height: 28px;
}

.table-map3 #p5 {
    left: 41px;
    top: 19px;
    width: 26px;
    height: 44px;
}

.table-map3 #p6 {
    left: 73px;
    top: 19px;
    width: 26px;
    height: 44px;
}

.table-map3 #p7 {
    left: 104px;
    top: 19px;
    width: 26px;
    height: 44px;
}

.table-map3 #p8 {
    left: 133px;
    top: 19px;
    width: 26px;
    height: 44px;
}

.table-map3 #p9 {
    left: 164px;
    top: 2px;
    height: 46px;
    width: 32px;
}

.table-map3 #p10 {
    left: 204px;
    top: 2px;
    height: 46px;
    width: 32px;
}

.table-map3 #p11 {
    left: 244px;
    top: 2px;
    height: 46px;
    width: 36px;
}

.table-map3 #p12 {
    left: 285px;
    top: 2px;
    height: 46px;
    width: 39px;
}

.table-map3 #p13 {
    left: 329px;
    top: 2px;
    height: 46px;
    width: 46px;
}

.table-map3 #p14 {
    left: 381px;
    top: 2px;
    height: 46px;
    width: 39px;
}

.table-map3 #p15 {
    left: 426px;
    top: 2px;
    height: 46px;
    width: 38px;
}

.table-map3 #p16 {
    left: 470px;
    top: 2px;
    height: 46px;
    width: 35px;
}

.table-map3 #p17 {
    left: 512px;
    top: 2px;
    height: 46px;
    width: 35px;
}

.table-map3 #p18 {
    left: 553px;
    top: 2px;
    height: 46px;
    width: 35px;
}

.table-map3 #p19 {
    left: 595px;
    top: 2px;
    height: 46px;
    width: 35px;
}

.table-map3 #p20 {
    left: 637px;
    top: 2px;
    height: 46px;
    width: 35px;
}

.table-map3 #p21 {
    left: 680px;
    top: 2px;
    height: 46px;
    width: 35px;
}

.table-map3 #p22 {
    left: 722px;
    top: 2px;
    height: 46px;
    width: 38px;
}

.table-map3 #p23 {
    left: 785px;
    top: 69px;
    width: 41px;
    height: 30px;
}

.table-map3 #p24 {
    left: 785px;
    top: 109px;
    width: 38px;
    height: 35px;
}

.table-map3 #p25 {
    left: 784px;
    top: 150px;
    width: 35px;
    height: 53px;
}

.table-map3 #p26 {
    left: 784px;
    top: 219px;
    width: 30px;
    height: 28px;
}

.table-map3 #p27 {
    left: 784px;
    top: 252px;
    width: 29px;
    height: 26px;
}

.table-map3 #p28 {
    left: 197px;
    top: 199px;
    width: 32px;
    height: 38px;
}

.table-map3 #p29 {
    left: 197px;
    top: 157px;
    width: 32px;
    height: 38px;
}

.table-map3 #p30 {
    left: 197px;
    top: 117px;
    width: 32px;
    height: 38px;
}

.table-map3 #p31 {
    left: 197px;
    top: 75px;
    width: 32px;
    height: 38px;
}

.table-map3 #p32 {
    left: 246px;
    top: 74px;
    width: 34px;
    height: 29px;
}

.table-map3 #p33 {
    left: 246px;
    top: 119px;
    width: 34px;
    height: 28px;
}

.table-map3 #p34 {
    left: 246px;
    top: 152px;
    width: 34px;
    height: 28px;
}

.table-map3 #p35 {
    left: 246px;
    top: 198px;
    width: 34px;
    height: 38px;
}

.table-map3 #p36 {
    left: 285px;
    top: 74px;
    width: 34px;
    height: 29px;
}

.table-map3 #p37 {
    left: 285px;
    top: 107px;
    width: 34px;
    height: 40px;
}

.table-map3 #p38 {
    left: 285px;
    top: 152px;
    width: 34px;
    height: 30px;
}

.table-map3 #p39 {
    left: 285px;
    top: 198px;
    width: 34px;
    height: 37px;
}

.table-map3 #p40 {
    left: 324px;
    top: 74px;
    width: 51px;
    height: 34px;
}

.table-map3 #p41 {
    left: 381px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p42 {
    left: 425px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p43 {
    left: 469px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p44 {
    left: 511px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p45 {
    left: 552px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p46 {
    left: 594px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p47 {
    left: 637px;
    top: 74px;
    width: 39px;
    height: 34px;
}

.table-map3 #p48 {
    left: 681px;
    top: 74px;
    width: 34px;
    height: 34px;
}

.table-map3 #p49 {
    left: 693px;
    top: 114px;
    width: 34px;
    height: 29px;
}

.table-map3 #p50 {
    left: 693px;
    top: 147px;
    width: 34px;
    height: 29px;
}

.table-map3 #p51 {
    left: 693px;
    top: 182px;
    width: 34px;
    height: 29px;
}

.table-map3 #p52 {
    left: 693px;
    top: 216px;
    width: 34px;
    height: 29px;
}

.table-map3 #p53 {
    left: 693px;
    top: 249px;
    width: 34px;
    height: 29px;
}

.table-map3 #p54 {
    left: 693px;
    top: 284px;
    width: 34px;
    height: 29px;
}

.table-map3 #p55 {
    left: 733px;
    top: 296px;
    width: 78px;
    height: 32px;
}

.table-map3 #p56 {
    left: 745px;
    top: 252px;
    width: 35px;
    height: 25px;
}

.table-map3 #p57 {
    left: 745px;
    top: 222px;
    width: 35px;
    height: 25px;
}

.table-map3 #p58 {
    left: 745px;
    top: 172px;
    width: 35px;
    height: 30px;
}

.table-map3 #p59 {
    left: 745px;
    top: 138px;
    width: 35px;
    height: 28px;
}

.table-map3 #p60 {
    left: 745px;
    top: 106px;
    width: 35px;
    height: 28px;
}

.table-map3 #p61 {
    left: 745px;
    top: 71px;
    width: 35px;
    height: 28px;
}