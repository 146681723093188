.box1 {
    background: transparent linear-gradient(90deg, #F64E2D 0%, #9B3E2B 100%) 0% 0% no-repeat padding-box;
}

.box2 {
    background: transparent linear-gradient(90deg, #2185E9 0%, #17538F 100%) 0% 0% no-repeat padding-box;
}

.box3 {
    background: transparent linear-gradient(90deg, #07BFBF 0%, #056767 100%) 0% 0% no-repeat padding-box;
}

.dash-box {
    padding: 30px;
}

.dash-box>div {
    justify-content: flex-start;
}

.dash-box h2 {
    color: #fafafa;
    font-weight: 400;
    font-size: 1.5rem;
    padding-right: 25px;
    margin-bottom: 1.5rem;
}

.dash-box h3 {
    font-weight: 600;
    font-size: 2rem;
    color: #fff;
    padding-right: 30px;

}

.dash-box h3 span {
    font-size: 1.3rem;
    font-weight: 400;
    padding-right: 7px;
    top: 3px;
}

.dash-row {
    padding: 12px 20px
}

.dash-row h2 {
    color: #000;
    font-weight: 500;
    font-size: 1.4rem;
}

.dash-row h4 {
    font-weight: 400;
    font-size: 1.5rem;
}

.dash-row span b {
    font-size: 1.3rem;
}

.dash-row .active h4 {
    color: #A580CA;
}

.dash-row .active span {
    background-color: #A580CA29 !important;
}

.dash-row .active span b {
    color: #A580CA;
}

.dash-row .inactive h4 {
    color: #000000;
}

.dash-row .inactive span {
    background-color: #70707029 !important;
}

.dash-row .inactive span b {
    color: #707070;
}

.dash-info>div>p>span{
    direction: ltr;
}

body[dir="ltr"] .dash-info {
    flex-direction: row-reverse !important;
}

body[dir="ltr"] .dash-box h2 {
    padding-right: 0;
    padding-left: 25px;
}

body[dir="ltr"] .dash-box h3 {
    padding-right: 0;
    padding-left: 30px;
}

body[dir="ltr"] .dash-box h3 span {
    padding-right: 0;
    padding-left: 7px;
}

@media (max-width: 920px) {
    .dash-info {
        flex-direction: column;
    }
}